.depositFunds-box {
  .form-box {
    .box {
      p {
        font-size: 15px;
        color: $blue;
        margin-bottom: 10px;
      }

      strong {
        line-height: 1.799;
        color: $red;
        text-transform: uppercase;
      }

      .info {
        p {
          font-size: 12px;
          color: rgb(98, 109, 113);
          line-height: 1.2;
          margin-top: 10px;
        }
        .info_red {
          color: $red;
        }
      }
    }

    .box_top {
      display: grid;
      grid-template-columns: calc(100% - 200px) 155px;
      column-gap: 45px;
      max-width: 750px;

      .box_top_left {
        /deep/.bank_info li {
          font-size: 15px;
          line-height: 1.799;

          .title_name {
            color: $bottle-blue;
            display: block;
          }

          .value_info {
            display: block;
            color: rgb(98, 109, 113);
            margin-bottom: 10px;
          }
        }

        /deep/ .el-form-item {
          width: 60%;
        }
      }

      .box_top_right {
        width: 155px;
        margin-top: 60px;

        .logo {
          vertical-align: baseline;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          align-self: end;

          &.circle {
            width: 110px;
            height: 110px;

            &.international {
              background-image: url('~@/assets/images/channel/international.png');
            }

            &.bank {
              background-image: url('~@/assets/images/channel/bank.png');
            }

            &.bankNga {
              background-image: url('~@/assets/images/channel/bank_nigeria.png');
            }
          }
        }
      }
    }

    .box_bottom {
      max-width: 664px;

      .warn_info {
        border-style: solid;
        border-width: $border-width;
        border-color: $border-color;
        border-radius: 6px;
        background-color: $white;
        padding: 20px 20px 10px;
        margin-bottom: 10px;

        p {
          font-size: 12px;
          color: rgb(100, 109, 114);
          line-height: 1.5;
        }
      }

      .form-list ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(50% - 10px));
        column-gap: 20px;

        .special_input_demo {
          font-size: 15px;
          color: rgb(98, 109, 113);
          line-height: 55px;
          height: 55px;
        }

        .warn_info {
          margin-top: 10px;
        }

        .btn-blue {
          width: 100%;
        }

        .data {
          .exchange {
            margin-top: 10%;
          }
        }
      }
    }
  }
}

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 760px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: calc(100% - 810px) !important;
  }
}

@media (max-width: 1024px) {
  .depositFunds-box .form-box {
    .box_top {
      grid-template-columns: calc(100% - 145px) 125px;
      column-gap: 20px;

      .box_top_right {
        margin-top: 0;
      }
    }

    .box_bottom {
      @include rtl-sass-prop(margin-right, margin-left, 20px);
    }
  }
}

@media (max-width: 767px) {
  .depositFunds-box .form-box {
    .box_top {
      grid-template-columns: repeat(auto-fill, 100%);
      grid-template-rows: auto;
      grid-template-areas:
        'form-right'
        'form-left';

      .box_top_left {
        grid-area: form-left;

        /deep/ .el-form-item {
          width: 100%;
        }
      }

      .box_top_right {
        grid-area: form-right;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;

        .logo {
          margin: 0 auto;
        }
      }
    }

    .box_bottom {
      .warn_info {
        border-style: solid;
        border-width: $border-width;
        border-color: rgba(39, 5, 109, 0.04);
        border-radius: 6px;
        background-color: $light-gray;
      }

      .form-list ul {
        grid-template-columns: repeat(auto-fill, 100%);

        .data {
          .exchange {
            margin-top: 0;
          }
        }
      }
    }
  }

  /deep/ .el-input__inner,
  /deep/ .el-select .el-input__inner {
    border-style: solid;
    border-width: $border-width;
    border-color: $border-color;
    border-radius: 6px;
    background-color: $light-gray;
  }
}
