.success {
  border-radius: 8px;
  background-color: $light-gray;
  padding: 60px 120px;
  max-width: 1200px;
  margin: 0 auto;

  .success-content {
    max-width: 780px;
    margin: 0 auto;
    text-align: center;

    img {
      margin-bottom: 55px;
    }

    p {
      font-size: 15px;
      line-height: 1.667;
      color: rgb(98, 109, 113);
      margin-bottom: 100px;

      /deep/ a {
        color: #a2d4ff;
        text-decoration: underline;
        word-wrap: break-word;
      }
    }

    .el-button {
      width: 100%;
      max-width: 339px;
    }
  }
}

@media (max-width: 767px) {
  .success {
    padding: 40px 20px;
  }
}
